import React from "react";
import PropTypes from "prop-types";

import copy from "assets/icons/Copy.svg";

export default function CopyToClipboard({ textToCopy }) {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy);
      //console.log('Text copied to clipboard!');
    } catch (err) {
      //console.error('Unable to copy text: ', err);
    }
  };

  return (
    <div>
      <div className="share-modal-link-container">
        <div className="share-modal-link-text">{textToCopy}</div>

        <button className="share-modal-link-btn" onClick={copyToClipboard}>
          <img src={copy} />
          Copy
        </button>
      </div>
    </div>
  );
}

CopyToClipboard.propTypes = {
  textToCopy: PropTypes.string.isRequired,
};
