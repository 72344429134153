import React from "react";
import PropTypes from "prop-types";
import {Checkbox} from "../base/Checkbox";

export const MultiSelectCheckbox = ({selectedCategories, setSelectedCategories, putToLocalStorage}) => {
    // Handler to toggle the checked status

    function saveSelectedCategories(newCategories) {
        putToLocalStorage("selectedCategories", newCategories);
    }

    const handleCheckboxChange = (index) => {
        const newCategories = [...selectedCategories];
        newCategories[index].checked = !newCategories[index].checked;
        setSelectedCategories(newCategories);
        saveSelectedCategories(newCategories);
    };

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridGap: "16px",
            }}
        >
            {selectedCategories.map((cat, index) => (
                <div className="filter-group" key={cat.label}>
                    <Checkbox
                        checked={cat.checked}
                        onChange={() => handleCheckboxChange(index)}
                        label={cat.label[0].toUpperCase() + cat.label.slice(1)}
                    />
                </div>
            ))}
        </div>
    );
}

MultiSelectCheckbox.propTypes = {
    selectedCategories: PropTypes.array.isRequired,
    setSelectedCategories: PropTypes.func.isRequired,
    putToLocalStorage: PropTypes.func.isRequired,
}