// LoginButton.js
// Adam Berger
// July 2023

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import userPlus from "assets/icons/user_plus.svg";
import user from "assets/icons/user.svg";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="nav-btn-container">
      <div
        className="nav-btn sign-up-btn"
        onClick={() =>
          loginWithRedirect({
            appState: {
              returnTo: "/browse",
            },
          })
        }
      >
        <img className="btn-icon sign-up-btn-icon" src={userPlus} />
        <div>Sign up</div>
      </div>
      <div
        className="nav-btn log-in-btn"
        onClick={() =>
          loginWithRedirect({
            appState: {
              returnTo: "/browse",
            },
          })
        }
      >
        <img className="btn-icon log-in-btn-icon" src={user} />
        <div>Log in</div>
      </div>
    </div>
  );
};

export default LoginButton;
