/*
Browse.js
Adam Berger
July 2023
*/
import React from "react";
import {
  InstantSearch,
  Configure,
  Hits,
  Pagination,
  SearchBox,
} from "react-instantsearch-hooks-web";
import * as Constants from "components/Constants";

//import assets
import funnel from "assets/icons/funnel.svg";
import magnifyingGlass from "assets/icons/MagnifyingGlass.svg";

import toggleOff from "assets/icons/TogglerOff.svg";
import toggleOn from "assets/icons/TogglerOn.svg";
import "satellite-min.css";
import "../../browse.scss";

//import components
import Navbar from "components/Navbar";
import {
  CustomStats,
  MultiSelectCheckbox,
  PendingRecipes,
  NutritionalFilterCheckboxes,
} from "../../components/BrowsePageComponents";
import { useBrowse } from "./useBrowse";
import WelcomeModal from "../../components/Welcome";

import { useState } from "react";

export const Browse = () => {
  const {
    nonce,
    buildFilterQuery,
    numHits,
    toggleSearchFilters,
    showSearchFilters,
    onlyShowMyRecipes,
    toggleOnlyShowMyRecipes,
    nutritionalFilterState,
    toggleFilter,
    setNumHits,
    selectedCategories,
    Hit,
    HITS_PER_PAGE,
    setSelectedCategories,
    putToLocalStorage,
    recentlyAddedRecipes,
    showOneTimeWelcomeMessage,
    welcomeModalIsOpen,
    disableWelcomeModal,
  } = useBrowse();

  return (
    <>
      <Navbar />
      <div style={{ overflowX: "hidden" }}>
        <div className="page_container recipe-section-container">
          <div key={nonce}>
            {" "}
            {/* force re-render when nonce changes */}
            <InstantSearch
              searchClient={Constants.searchClient}
              indexName={Constants.ALGOLIA_INDEX_NAME}
            >
              <Configure
                hitsPerPage={HITS_PER_PAGE}
                filters={buildFilterQuery()}
              />

              <div className="recipe-title-container">
                <div className="recipe-title">
                  <h4 className="recipe-title-text">All Recipes</h4>
                  <p className="recipe-nums recipe-title-text">
                    &nbsp;({numHits})
                  </p>
                </div>

                <div className="toggle-filters-container">
                  <p className="toggle-filters-text">Filter recipes</p>
                  <div
                    onClick={toggleSearchFilters}
                    className="toggle-filters-button"
                  >
                    <img src={funnel} />
                  </div>
                </div>
              </div>

              {/* Flex container */}
              <div className="browse-flex-container">
                {/* Left column for Search and Filters */}
                <div className="left-column">
                  {" "}
                  {/* Prevent shrinking */}
                  <div className="browse-search-container">
                    <SearchBox placeholder="Search for anything ..." />
                    <img
                      className="browse-search-magnify"
                      src={magnifyingGlass}
                    />
                  </div>
                  {/* Toggle Button */}
                  <div
                    className={`filters ${showSearchFilters ? "show" : "hide"}`}
                  >
                    <div className="recipes-view-toggle">
                      <label className="toggle-option">
                        <input
                          type="radio"
                          name="recipeOption"
                          value="myRecipes"
                          checked={onlyShowMyRecipes}
                          onChange={() => toggleOnlyShowMyRecipes(true)}
                        />
                        Only show my recipes
                      </label>
                      <label className="toggle-option">
                        <input
                          type="radio"
                          name="recipeOption"
                          value="allRecipes"
                          checked={!onlyShowMyRecipes}
                          onChange={() => toggleOnlyShowMyRecipes(false)}
                        />
                        Show everyone&apos;s recipes
                      </label>
                    </div>

                    <div className="filter-layout">
                      <div className="category-filter-container">
                        <div className="filter-title">Show these recipes: </div>
                        <MultiSelectCheckbox
                          selectedCategories={selectedCategories}
                          setSelectedCategories={setSelectedCategories}
                          putToLocalStorage={putToLocalStorage}
                        />{" "}
                        {/* category filter */}
                      </div>

                      <div className="nutritional-filter-container">
                        <div className="filter-title">Restrict to:</div>
                        <NutritionalFilterCheckboxes
                          nutritionalFilterState={nutritionalFilterState}
                          toggleFilter={toggleFilter}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right column for Search Results */}
                <div className="right-column">
                  {" "}
                  {/* Take remaining space */}
                  <CustomStats
                    setNumHits={setNumHits}
                    nutritionalFilterState={nutritionalFilterState}
                    selectedCategories={selectedCategories}
                  />
                  <div>
                    <PendingRecipes
                      recentlyAddedRecipes={recentlyAddedRecipes}
                    />
                    <Hits hitComponent={Hit} />
                  </div>
                  {numHits > HITS_PER_PAGE && (
                    <Pagination className="Pagination" />
                    // <ConnectedPagination className="Pagination" />
                  )}
                </div>
              </div>
            </InstantSearch>
          </div>
        </div>
      </div>
      {welcomeModalIsOpen && (
        <WelcomeModal toggle={disableWelcomeModal}></WelcomeModal>
      )}
    </>
  );
};
