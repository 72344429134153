/*
CreateMenu
Adam Berger
July 2023
*/

import React from 'react'
import MKTypography from 'components/MKTypography'
import { useNavigate } from 'react-router-dom'
import Navbar from 'components/Navbar'
import RecipePageBackground from 'components/RecipePageBackground'
import Footer from 'components/Footer'
import bgImage from 'assets/images/1.png'
import Button from "components/Button"

import iconKitchen from 'assets/icons/kitchen.png'
import iconUpload from 'assets/icons/upload.png'
import iconInternet from 'assets/icons/internet.png'
import iconConstruction from 'assets/icons/construction.png'

export const CreateMenu = () => {
    const navigate = useNavigate()

    return (
        <>
            <Navbar/>
            <RecipePageBackground bgImage={bgImage}>
                <div className="page_container">
                    <MKTypography variant="small">
                        How do you want to create a new recipe?
                    </MKTypography><br /><br />
                    <div className="menu_grid_container">
                        <div className="menu_grid_item"><Button onClick={() => navigate("/createFromIngredients")}><img src={iconKitchen} />Type some ingredients</Button></div>
                        <div className="menu_grid_item"><Button onClick={() => navigate("/createFromImage")}> <img src={iconUpload} /> Upload a picture from a cookbook</Button></div>
                        <div className="menu_grid_item"><Button onClick={() => navigate("/createFromUrl")}> <img src={iconInternet} /> Type the URL of a recipe</Button></div>
                        <div className="menu_grid_item"><Button onClick={() => navigate("/edit/new")}> <img src={iconConstruction} /> Build a recipe from scratch</Button></div>
                    </div>
                </div >
            </RecipePageBackground >
            <Footer />
        </>
    );
}
