import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";
 
//import assets
import modalX from "assets/icons/modal-x.svg";
import checkMarkWhite from "assets/icons/check-mark-white.svg";

export default function FeedbackModal({
  backdrop,
  toggle,
  feedback,
  onFeedbackChange,
  onSubmitFeedback,
  onCancel,
}) {
  return (
    <Modal
      backdrop={backdrop}
      toggle={toggle}
      isOpen={true}
      className="modal-dialog"
    >
      <div className="modal-header">
        <div className="modal-header-flex">
          <h5 className="modal-header-text">What do you think?</h5>
          <img
            className="modal-header-x"
            src={modalX}
            onClick={(e) => {
              e.stopPropagation();
              onCancel();
            }}
          />
        </div>
      </div>
      <ModalBody>
        <div className="feedback-modal-input-group">
          <label className="feedback-modal-label">Your Feedback</label>
          <textarea
            className="feedback-modal-textarea"
            value={feedback}
            placeholder="Type your feedback here"
            rows={4}
            style={{ width: "100%" }}
            onChange={(e) => onFeedbackChange(e.target.value)}
          />
        </div>
      </ModalBody>
      <div className="variant-modal-footer">
        <button
          className="variant-modal-btn variant-modal-btn-dark"
          onClick={onSubmitFeedback}
        >
          <img src={checkMarkWhite} />
          Submit
        </button>
        <button
          className="variant-modal-btn variant-modal-btn-light"
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          <img src={modalX} />
          Cancel
        </button>
      </div>
    </Modal>
  );
}

FeedbackModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onFeedbackChange: PropTypes.func.isRequired,
  onSubmitFeedback: PropTypes.func.isRequired,
  feedback: PropTypes.string,
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};
