import {Player} from "@lottiefiles/react-lottie-player";
import loading from "../../assets/icons/Kasserole_LoadingAnimation.json";
import React from "react";

export const PendingRecipes = ({recentlyAddedRecipes}) => {
    return (
        <div className="ais-Hits-list">
            {Array.from(recentlyAddedRecipes).map((index) => (
                <div key={index} className="ais-Hits-item">
                    <div className="browse-grid-item">
                        <div className="browse-loading-item">
                            <div className="loading-background">
                                <Player
                                    className="browse-Lottie-player"
                                    src={loading}
                                    loop
                                    autoplay
                                />
                            </div>
                            <p>Building new recipe...</p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

PendingRecipes.propTypes = {
    recentlyAddedRecipes: Set.isRequired,
}