import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import PropTypes from "prop-types";
import * as Constants from "../../components/Constants";
import * as LocalCache from "../../components/LocalCache";
import { Link } from "react-router-dom";
import WrappedImg from "../../components/WrappedImg";
import {
  FILTER_STICKY_DURATION,
  INITIAL_NUTRITIONAL_FILTER_STATE,
  INITIAL_CATEGORIES,
  FILTER_MAPPINGS,
} from "../../settings";
import { callExternalApi } from "../../components/external-api.service";

let HITS_PER_PAGE = 9; // must be divisible by 3, since we're using 3 columns for wide-screen displays

export const useBrowse = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [welcomeModalIsOpen, setWelcomeModalIsOpen] = useState(null);

  const disableWelcomeModal = () => {
    localStorage.setItem("userVisited", "true");
    setWelcomeModalIsOpen(false);
  };

  const loadUserDetail = async () => {
    let accessToken;
    try {
      accessToken = await getAccessTokenSilently();
    } catch (err) {
      console.log("Error getting access token:" + err);
      return;
    }
    if (accessToken == null) {
      console.log("No access token - can't fetch user detail");
      return;
    }
    const url = Constants.AUTH0_USER_INFO_URL;
    const { data, error } = await callExternalApi(url, accessToken);

    if (!data) {
      console.log("No data returned from user detail API");
      return;
    }
    if (error) {
      console.log("Error returned from user detail:" + error);
      return;
    }

    if (localStorage.getItem("userVisited") === "true") {
      setWelcomeModalIsOpen(false);
    } else {
      setWelcomeModalIsOpen(true);
    }
  };

  useEffect(() => {
    let isMounted = true;
    const loadDataWrapper = async () => {
      await loadUserDetail();
      if (!isMounted) {
        return;
      }
    };
    loadDataWrapper();
    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently]);

  const [numHits, setNumHits] = useState(0);
  const { user } = useAuth0();
  const { refresh } = useInstantSearch();

  //check for window size
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // adjust hits on load
  const adjustHits = () => {
    if (screenSize.width >= 1025) {
      HITS_PER_PAGE = 9;
    } else if (screenSize.width < 1024) {
      HITS_PER_PAGE = 8;
    }
  };
  adjustHits();

  useEffect(() => {
    //update screen size state
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // add event listener to update screen size and update hits per page accordingly
    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", adjustHits);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
      window.addEventListener("resize", adjustHits);
    };
  }, [screenSize]);

  // Data fetched from LocalCache (representing recently added, deleted, and edited recipes)
  const [recentlyAddedRecipes, setRecentlyAddedRecipes] = useState(new Set());
  const [recentlyDeletedRecipes, setRecentlyDeletedRecipes] = useState(
    new Set()
  );
  const [recentlyEditedRecipes, setRecentlyEditedRecipes] = useState(new Set());
  const [namesOfRecipes, setNamesOfRecipes] = useState({});
  const [imagesOfRecipes, setImagesOfRecipes] = useState({});

  // used to force a reload of the search results
  const [nonce, setNonce] = useState(0);

  // on mobile, the filters panel can collapse
  const [showSearchFilters, setShowSearchFilters] = useState(false);

  const [onlyShowMyRecipes, setOnlyShowMyRecipes] = useState(true);
  const [selectedCategories, setSelectedCategories] =
    useState(INITIAL_CATEGORIES);
  const [nutritionalFilterState, setNutritionalFilterState] = useState(
    INITIAL_NUTRITIONAL_FILTER_STATE
  );

  useEffect(() => {
    setOnlyShowMyRecipes(getFromLocalStorage("onlyShowMyRecipes", true));
    setSelectedCategories(
      getFromLocalStorage("selectedCategories", INITIAL_CATEGORIES)
    );
    setNutritionalFilterState(
      getFromLocalStorage(
        "nutritionalFilterState",
        INITIAL_NUTRITIONAL_FILTER_STATE
      )
    );
  }, []);

  // show/hide the search filters
  const toggleSearchFilters = () => {
    setShowSearchFilters((prevState) => !prevState);
  };
 
  function toggleOnlyShowMyRecipes(b) {
    setOnlyShowMyRecipes(b);
    putToLocalStorage("onlyShowMyRecipes", b);
  }

  const toggleFilter = (filterName) => {
    setNutritionalFilterState((prevState) => {
      const newState = {
        ...prevState,
        [filterName]: !prevState[filterName],
      };
      const stateWithTimestamp = {
        state: newState,
        timestamp: new Date().getTime(),
      };
      localStorage.setItem(
        "nutritionalFilterState",
        JSON.stringify(stateWithTimestamp)
      );
      return newState;
    });
  };

  function buildFilterQuery() {
    // step 1: build nutritional filters
    let nutritionalFilters = Object.entries(nutritionalFilterState)
      .filter(([, value]) => value)
      .map(([key]) => FILTER_MAPPINGS[key])
      .join(" AND ");
    nutritionalFilters = nutritionalFilters
      ? ` AND (${nutritionalFilters})`
      : "";

    // step 2: build category filters
    let categoryFilters = selectedCategories
      .filter((cat) => cat.checked)
      .map((cat) => `category:'${cat.label}'`);
    categoryFilters = categoryFilters.join(" OR ");
    categoryFilters = categoryFilters ? ` AND (${categoryFilters})` : "";
    if (categoryFilters === "") {
      categoryFilters = ' AND category:"xx"';
    }

    // step 3: apply 'only show my recipes' flag, if active
    let onlyMineFilter = "";
    if (onlyShowMyRecipes) {
      onlyMineFilter = " AND userid: " + Constants.encodeUser(user);
    }

    let finalQuery = `${nutritionalFilters} ${categoryFilters} ${onlyMineFilter}`;
    finalQuery = finalQuery.replace(
      /\s*AND /,
      ""
    ); /* remove leading 'AND', if present */
    return finalQuery;
  }

  function getFromLocalStorage(key, defaultVal) {
    let returnVal;
    const savedData = JSON.parse(localStorage.getItem(key));
    const currentTime = new Date().getTime();

    // Check if the data exists and is within the 10-minute timeout window
    if (
      savedData &&
      currentTime - savedData.timestamp < FILTER_STICKY_DURATION
    ) {
      returnVal = savedData.state;
    } else {
      localStorage.removeItem(key); // Clear expired data
      returnVal = defaultVal; // default value
    }

    return returnVal;
  }

  function putToLocalStorage(key, value) {
    const stateWithTimestamp = {
      state: value,
      timestamp: new Date().getTime(),
    };
    localStorage.setItem(key, JSON.stringify(stateWithTimestamp));
  }

  // We want to give new recipes time before rendering them, to wait for their thumbnail to be available.
  // To that end, this is a dictionary that associates a state with every possible guid.
  // Allowable values are None (never saw this guid), WAITING_FOR_THUMBNAIL (it's been <60s since first seeing it), and TIMED_OUT (timer has expired).
  const WAITING_FOR_THUMBNAIL = "waiting_for_thumbnail",
    TIMED_OUT = "timed_out";
  const [waitingForThumbnail, setWaitingForThumbnail] = useState({});

  async function refreshCache() {
    refresh(); // force a reload of the search results
    try {
      const [addedRecipes, deletedRecipes, editedRecipes, names, images] =
        await LocalCache.get();
      setRecentlyAddedRecipes(addedRecipes);
      setRecentlyDeletedRecipes(deletedRecipes);
      setRecentlyEditedRecipes(editedRecipes);
      setNamesOfRecipes(names);
      setImagesOfRecipes(images);
    } catch (error) {
      console.error("Error fetching data from LocalCache:", error);
    }
  }

  // populate cache
  useEffect(() => {
    refreshCache();
  }, [nonce]);

  // while there's pending recipes, keep refreshing the page every n seconds
  const TIME_BETWEEN_REFRESHES_WHEN_RECIPES_ARE_PENDING = 5000; // in ms
  useEffect(() => {
    let interval;
    if (recentlyAddedRecipes.size > 0) {
      interval = setInterval(() => {
        refreshCache();
        setNonce((prevNonce) => prevNonce + 1);
      }, TIME_BETWEEN_REFRESHES_WHEN_RECIPES_ARE_PENDING);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [recentlyAddedRecipes.size, nonce]);

  // Reset the state on unmount
  useEffect(() => {
    return () => {
      setNumHits(0);
    };
  }, []); // Empty dependency array to run only on mount and unmount

  /* render one search result */
  function Hit({ hit }) {
    const guid = hit.guid;

    // don't render deleted recipes
    if (recentlyDeletedRecipes.has(guid)) {
      return null;
    }

    let recipe_name = hit.recipe_name;
    let thumb_image_url = hit.thumb_image_url; // Default: Algolia's stored thumbnail
    if (recentlyEditedRecipes.has(guid)) {
      // Recipe was recently edited. Algolia's data is likely out of date. Use LocalCache instead.
      recipe_name = namesOfRecipes[guid];
      const recipe_image = imagesOfRecipes[guid];
      if (recipe_image) {
        thumb_image_url = URL.createObjectURL(recipe_image);
      }
    }

    if (recentlyAddedRecipes.has(guid)) {
      // This recipe is now appearing in the Algolia search results. However, we don't want to show the recipe until the thumbnail is available.
      // When recipe shows up the first time in recentlyAddedRecipes, we set a timer, and wait to show the recipe until after the
      // timer expires, or when the thumbnail appears - whichever comes first.
      if (!(guid in waitingForThumbnail)) {
        // Recipe just appeared in 'recentlyAddedRecipes' for the first time. Set the timer.
        // console.log("Recipe " + recipe_name + " just appeared. Setting timer for 30s before rendering (to wait for thumbnail") // debug
        waitingForThumbnail[guid] = WAITING_FOR_THUMBNAIL;
        setTimeout(() => {
          // console.log("Times up for " + recipe_name); // debug
          setWaitingForThumbnail((prevState) => ({
            ...prevState,
            [guid]: TIMED_OUT,
          }));
        }, 30000);
      }

      if (thumb_image_url || waitingForThumbnail[guid] === TIMED_OUT) {
        // Ok, we're done waiting for the thumbnail. Remove recipe from LocalCache of recently-added items.
        // console.log("we're done waiting for thumbnail") // debug
        LocalCache.deleteByGuid(guid);
      }

      return; // leave the Hit() function without rendering this recipe
    }

    const url = Constants.ROUTE_DETAIL + guid;
    const key = guid + thumb_image_url; // unique key for this hit

    const userOwnsThisRecipe = hit.userid === Constants.encodeUser(user);

    return (
      <Link to={url}>
        <div className="browse-grid-item" key={key}>
          <div className="thumb_image_container">
            <WrappedImg
              src={thumb_image_url}
              defaultSrc={Constants.DEFAULT_RECIPE_THUMBNAIL_IMAGE}
              className="thumb_image"
            />
          </div>
          <p>
            {hit.error ? (
              <>Couldn&apos;t create recipe (click for details)</>
            ) : (
              recipe_name
            )}
            <>{userOwnsThisRecipe ? "*" : ""}</>
          </p>
        </div>
      </Link>
    );
  }

  Hit.propTypes = {
    hit: PropTypes.shape({
      guid: PropTypes.string.isRequired,
      userid: PropTypes.string.isRequired,
      recipe_name: PropTypes.string.isRequired,
      thumb_image_url: PropTypes.string.isRequired,
      error: PropTypes.string, // optional
    }).isRequired,
  };

  return {
    nonce,
    buildFilterQuery,
    numHits,
    toggleSearchFilters,
    showSearchFilters,
    onlyShowMyRecipes,
    toggleOnlyShowMyRecipes,
    nutritionalFilterState,
    toggleFilter,
    setNumHits,
    selectedCategories,
    Hit,
    HITS_PER_PAGE,
    setSelectedCategories,
    putToLocalStorage,
    recentlyAddedRecipes,
    welcomeModalIsOpen,
    disableWelcomeModal,
  };
};
