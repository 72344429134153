import {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import * as Constants from "../../components/Constants";
import {callExternalApi} from "../../components/external-api.service";
import * as LocalCache from "../../components/LocalCache";
import axios from "axios";
import {MAX_RETRIES_FOR_IMAGE_SUBMIT, RETRY_DELAY} from "../../settings";

export const useEdit = () => {

    const [recipe, setRecipe] = useState(null);
    const [imageFileToUpload, setImageFileToUpload] = useState(null); // binary image, of 'File' type
    const [imageFileToUploadAsB64, setImageFileToUploadAsB64] = useState(null); // b64-encoded version of above
    const [whyRecipeIsInvalid, setWhyRecipeIsInvalid] = useState("");

    // UI state
    const [userHasMadeEdits, setUserHasMadeEdits] = useState(false);
    const [userHasEditedBody, setUserHasEditedBody] = useState(false);
    const [saveModalIsActive, setSaveModalIsActive] = useState(false);
    const [invalidInputModalIsActive, setInvalidInputModalIsActive] =
        useState(false);
    const [imageSelectorModalIsActive, setImageSelectorModalIsActive] =
        useState(false);
    const [modalErrorMessage, setModalErrorMessage] = useState(null);

    var {guid} = useParams();
    const recipe_is_new = guid == "new";


    // avoid calling loadRecipeDetail() 2x on component load.
    const alreadyCalledLoadRecipeDetail = useRef(false);

    const [userOwnsThisRecipe, setUserOwnsThisRecipe] = useState(false);

    const navigate = useNavigate();

    const {user, getAccessTokenSilently, loginWithRedirect} = useAuth0();

    function getUpdateOrNewMessage() {
        return recipe_is_new ? "Recipe created." : "Recipe updated.";
    }

    const loadRecipeDetail = async () => {
        if (alreadyCalledLoadRecipeDetail.current) {
            return;
        }
        alreadyCalledLoadRecipeDetail.current = true;

        if (recipe_is_new) {
            const newrecipe = {
                recipe_name: "",
                recipe_body: "",
            };
            setRecipe(newrecipe);
            return;
        }
        const encodedUser = Constants.encodeUser(user);
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_GET_RESOURCE,
            "user=" + encodedUser + "&guid=" + guid
        );
        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );
        const {data} = await callExternalApi(url, accessToken);
        if (data.recipe_name == "**ITEM NOT FOUND**") {
            setModalErrorMessage(
                "That recipe does not exist or has recently been deleted."
            );
            return;
        }

        // fill missing fields with defaults, etc.
        const r = Constants.trueUpRecipe(data);

        setRecipe(r);

        if (data.userid == encodedUser) {
            setUserOwnsThisRecipe(true);
        }
    };

    const submit = async () => {
        const encodedUser = Constants.encodeUser(user);
        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );

        // sanity checks on recipe
        const ri = recipe.recipe_body.trim();
        const rname = recipe.recipe_name;
        if (rname.length < Constants.MIN_RECIPE_NAME_LENGTH) {
            setInvalidInputModalIsActive(true);
            setWhyRecipeIsInvalid(
                "Recipe name must be at least " +
                Constants.MIN_RECIPE_NAME_LENGTH +
                " characters in length."
            );
            return;
        }
        if (ri.length < Constants.MIN_RECIPE_BODY_LENGTH) {
            setInvalidInputModalIsActive(true);
            setWhyRecipeIsInvalid(
                "Recipe must be at least " +
                Constants.MIN_RECIPE_BODY_LENGTH +
                " characters in length."
            );
            return;
        }

        setSaveModalIsActive(!saveModalIsActive);

        // looks good. Submit. We first submit the text, then (assuming success) the image, in a separate POST.

        // For an existing recipe, guid was passed into this component as a param. For a new recipe, we generate a new guid here.
        if (recipe_is_new) {
            guid = Constants.generateGUID();
        }

        // step 1: submit the text
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_UPDATE_RESOURCE,
            "mode=text" + "&guid=" + guid + "&user=" + encodedUser
        );
        var payload = {
            recipe_body: _b64(recipe.recipe_body),
            recipe_name: _b64(recipe.recipe_name),
            category: _b64(recipe.category),
            visibility: _b64(recipe.visibility),
        };
        if (!userHasEditedBody) {
            delete payload.recipe_body; // don't upload recipe body if it has not changed
        }

        // Store recipe data in local cache, so Browse can use the fresh data for the recipe, vs. relying on stale Algolia data.
        try {
            LocalCache.add(guid, LocalCache.EDIT_STATUS, rname, imageFileToUpload);
        } catch (error) {
            console.error("Error adding/updating item in IndexedDB:", error);
        }

        try {
            await axios.post(url, payload, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                    "Content-Type": "application/json",
                },
            });

            // step 2: submit the image, if there is one.
            if (imageFileToUpload) {
                submitImagePart();
            }
        } catch (error) {
            console.log("Error uploading recipe" + error);
        }
    };

    function _b64(str) {
        // 1. Use encodeURIComponent to get percent-encoded UTF-8 string
        const utf8String = encodeURIComponent(str);
        // 2. Convert the percent-encoded string into an actual byte string
        const byteString = utf8String.replace(/%([0-9A-F]{2})/g, function (_, p1) {
            return String.fromCharCode("0x" + p1);
        });
        // 3. Use btoa to encode the byte string to base64
        const base64String = btoa(byteString);
        return base64String;
    }



    const submitImagePart = async (retryCount = 0) => {
        if (recipe_is_new) {
            console.log("Error: guid should never be 'new' here");
            return;
        }
        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );
        const encodedUser = Constants.encodeUser(user);
        setSaveModalIsActive(!saveModalIsActive);
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_UPDATE_RESOURCE,
            "mode=image" + "&guid=" + guid + "&user=" + encodedUser
        );

        const reader = new FileReader();
        reader.onloadend = async () => {
            const arr = new Uint8Array(reader.result).subarray(0, 4);
            let header = "";
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            const mimeType = Constants.getImageMimeTypeFromHeader(header);
            if (mimeType === "") {
                console.log("Unrecognized mime type");
            } else {
                try {
                    await axios.post(url, imageFileToUpload, {
                        headers: {
                            "Content-Type": mimeType,
                            Authorization: "Bearer " + accessToken,
                        },
                    });
                } catch (error) {
                    console.log("Error submitting image part:", error);
                    if (retryCount < MAX_RETRIES_FOR_IMAGE_SUBMIT) {
                        console.log(`Retrying... Attempt ${retryCount + 1}`);
                        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
                        submitImagePart(retryCount + 1);
                    } else {
                        console.log("Max retries reached. Aborting.");
                    }
                }
            }
        };
        reader.readAsArrayBuffer(imageFileToUpload); // reading as binary
    };

    function useSelectedImage() {
        toggleImageSelectorModal();
    }

    function cancelSelectedImage() {
        toggleImageSelectorModal();
        setImageFileToUpload(null);
        setImageFileToUploadAsB64(null);
    }

    function toggleImageSelectorModal() {
        setImageSelectorModalIsActive(!imageSelectorModalIsActive);
    }

    function toggleVisibility() {
        let newVisibility = "";
        if (recipe.visibility == Constants.VISIBILITY_PRIVATE) {
            newVisibility = Constants.VISIBILITY_PUBLIC;
        } else {
            newVisibility = Constants.VISIBILITY_PRIVATE;
        }
        const updatedRecipe = {...recipe, visibility: newVisibility};
        setRecipe(updatedRecipe);
        setUserHasMadeEdits(true);
    }

    useEffect(() => {
        let isMounted = true;
        const loadDataWrapper = async () => {
            const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
                getAccessTokenSilently,
                loginWithRedirect,
                navigate
            );
            await loadRecipeDetail(accessToken);
            if (!isMounted) {
                return;
            }
        };
        loadDataWrapper();
        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently]);

    const handleImageFileChange = (event) => {
        setUserHasMadeEdits(true);
        const x = event.target.files[0];
        if (x == null) {
            return;
        }
        setImageFileToUpload(x); // NB: as always, assignment happens on next render, not immediately
        // store the b64-encoded version as well, for efficiency.
        let reader = new FileReader();
        reader.onload = function (event) {
            setImageFileToUploadAsB64(event.target.result);
        };
        reader.readAsDataURL(x); // reading the filename (as base-64)
    };

    const handleChangeInName = (event) => {
        setUserHasMadeEdits(true);
        // force the input to be N characters or less for the title
        let input = event.target.value;
        input = input.replace(/\n/g, ""); // remove newlines
        if (input.length > Constants.MAX_RECIPE_NAME_LENGTH) {
            input = input.substring(0, Constants.MAX_RECIPE_NAME_LENGTH);
        }
        const updatedRecipe = {...recipe, recipe_name: input};
        setRecipe(updatedRecipe);
    };

    const handleChangeInBody = (event) => {
        setUserHasMadeEdits(true);
        setUserHasEditedBody(true);
        // update character count
        let input = event.target.value;
        if (input.length > Constants.MAX_RECIPE_BODY_LENGTH) {
            input = input.substring(0, Constants.MAX_RECIPE_BODY_LENGTH);
        }
        const updatedRecipe = {...recipe, recipe_body: input};
        setRecipe(updatedRecipe);
    };


    return {
        modalErrorMessage,
        setModalErrorMessage,
        recipe,
        recipe_is_new,
        handleChangeInName,
        setRecipe,
        setUserHasMadeEdits,
        handleChangeInBody,
        imageFileToUploadAsB64,
        handleImageFileChange,
        submit,
        toggleVisibility,
        userOwnsThisRecipe,
        userHasMadeEdits,
        navigate,
        saveModalIsActive,
        getUpdateOrNewMessage,
        setSaveModalIsActive,
        invalidInputModalIsActive,
        whyRecipeIsInvalid,
        setInvalidInputModalIsActive,
        imageSelectorModalIsActive,
        imageFileToUpload,
        useSelectedImage,
        cancelSelectedImage,
    }
}