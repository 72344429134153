import React from "react";
import Navbar from "../../components/Navbar";
import homeImg from "../../assets/images/home_img.jpg";
import wand from "../../assets/icons/wand-white.svg";
import wandGrey from "../../assets/icons/wand.svg";
import ModalWithAck from "../../components/ModalWithAck";
//import css files
import "../../components/newRecipe.css";
import { useCreateFromIngredients } from "./useCreateFromIngredients";
import {
  MAX_CHARS_PER_INPUT_LINE,
  placeholder_ingredients,
} from "../../settings";

export const CreateFromIngredients = () => {
  const {
    user,
    handleChangeInInputIngredients,
    inputValue,
    submitIngredients,
    acknowledgeInputModalIsActive,
    navigate,
    invalidInputModalIsActive,
    inputFeedback,
    setInvalidInputModalIsActive,
  } = useCreateFromIngredients();

  return user === null ? (
    <div>Loading...</div>
  ) : (
    <div className="app-container">
      <Navbar />

      <div className="main-container">
        <div className="recipe-hero-img-container">
          <img className="recipe-hero-img" src={homeImg} />
          <div className="recipe-hero-img-overlay"></div>
          <div className="recipe-hero-content">
            <h5 className="recipe-hero-main-text">New Recipe</h5>
            <div className="recipe-hero-subtext">
              Type in some ingredients, one per line.<br></br>
              I&apos;ll come up with a recipe that&nbsp;
              <br className="line-break"></br>
              uses these ingredients.
            </div>
          </div>
        </div>

        <div className="form-container">
          <textarea
            className="text-area"
            rows={4}
            cols={MAX_CHARS_PER_INPUT_LINE + 1}
            onChange={handleChangeInInputIngredients}
            placeholder={placeholder_ingredients}
            value={inputValue}
            label="Type ingredients here"
          />

          <div className="new-recipe-disclaimer">
            By default, all new recipes are public, meaning that all Kasserole
            users can see them. If you want to make a recipe private, you can do
            so after it&apos;s created by editing that recipe.
          </div>

          <div>
            <div
              style={{
                borderTop: "1px solid #E1E7ED",
                width: "100%",
                marginBottom: "24px",
              }}
            ></div>
            <div
              className={`create-recipe-btn${
                inputValue !== "" ? "" : "-disabled"
              }`}
              onClick={() => submitIngredients()}
            >
              <img src={inputValue !== "" ? wand : wandGrey} />
              <div className="create-recipe-btn-text">Craft Recipe</div>
            </div>
          </div>
        </div>
      </div>

      {acknowledgeInputModalIsActive && (
        <ModalWithAck
          valid={true}
          title="I'm on it!"
          message="I'll work up a recipe for these ingredients. In a couple of minutes, you should see it appear in your cookbook, along with an AI-generated picture."
          onClick={() => navigate("/browse")}
        />
      )}

      {invalidInputModalIsActive && (
        <ModalWithAck
          valid={false}
          title="Oops!"
          message={inputFeedback}
          onClick={() => setInvalidInputModalIsActive(false)}
        />
      )}
    </div>
  );
};
