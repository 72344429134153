/*
ModalWithoutAck.js
Adam Berger
July 2023
*/

import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

//import assets
import modalX from "assets/icons/modal-x.svg";
import chefIcon from "assets/icons/chef-icon.png";
import checkcircle from "assets/icons/CheckCircle.svg";

/* Modal window that dismisses automatically after a given delay */
const ModalWithoutAck = ({
  backdrop,
  toggle,
  success,
  valid,
  title,
  message,
  delay,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose(); // Call the onClose function after the delay
    }, delay);
    return () => clearTimeout(timer); // Cleanup the timer
  }, [delay, onClose]);

  return (
    <Modal
      backdrop={backdrop}
      toggle={toggle}
      isOpen={true}
      className="modal-dialog"
    >
      <div className="modal-header">
        <div className="modal-header-flex">
          <h5 className="modal-header-text">{title}</h5>
          <img className="modal-header-x" src={modalX} onClick={onClose} />
        </div>
      </div>
      <ModalBody>
        <div className="modal-message-container">
          {success && (
            <img className="modal-body-img-success" src={checkcircle} />
          )}
          <div
            style={valid ? { textAlign: "center" } : { textAlign: "left" }}
            className="modal-body-text"
          >
            {message}
          </div>
        </div>
        {valid && <img className="modal-body-img" src={chefIcon} />}
      </ModalBody>
    </Modal>
  );
};

ModalWithoutAck.propTypes = {
  valid: PropTypes.bool,
  success: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  delay: PropTypes.number, // Add delay prop type
  onClose: PropTypes.func.isRequired, // Add onClose prop type
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};

export default ModalWithoutAck;
