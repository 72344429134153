/*
authentication-guard.js
Adam Berger
July 2023

cf: https://developer.auth0.com/resources/guides/spa/react/basic-authentication
*/

import React from "react";
import PropTypes from 'prop-types';
import { withAuthenticationRequired } from "@auth0/auth0-react";


export const AuthenticationGuard = ({ component }) => {

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>
          LOADING...
      </div>
    ),
  });
  return <Component />;
};

AuthenticationGuard.propTypes = {
  component: PropTypes.func.isRequired, 
};
