import React from "react";
import PropTypes from "prop-types";

export const Checkbox = ({checked, onChange, label}) => {
    return (
        <label className="custom-checkbox">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <span className="checkmark"></span>
            <span>{label}</span>
        </label>
    );
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    label: PropTypes.string.isRequired,
}