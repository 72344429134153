import React from "react";
import Navbar from "../../components/Navbar";
import homeImg from "../../assets/images/home_img.jpg";
import hyperlink from "../../assets/icons/hyperlink.svg";
import wandGrey from "../../assets/icons/wand.svg";
import wand from "../../assets/icons/wand-white.svg";
import ModalWithAck from "../../components/ModalWithAck";
import {useCreateFromUrl} from "./useCreateFromUrl";

export const CreateFromUrl = () => {
    const {
        user,
        handleChange,
        desiredUrl,
        submitUrl,
        acknowledgeInputModalIsActive,
        dismissModals,
        navigate,
        invalidInputModalIsActive
    } = useCreateFromUrl()

    return (
        user === null ?
            <div>Loading...</div> :
            <>
                <div className="app-container">
                    <Navbar />

                    <div className="main-container">
                        <div className="recipe-hero-img-container">
                            <img className="recipe-hero-img" src={homeImg} />
                            <div className="recipe-hero-img-overlay"></div>
                            <div className="recipe-hero-content">
                                <h5 className="recipe-hero-main-text">New Recipe</h5>
                                <div className="recipe-hero-subtext">
                                    Type a URL for a web page.<br></br>I will look for a recipe on
                                    that web page <br></br>and save it to your online cookbook.
                                </div>
                            </div>
                        </div>

                        <div className="form-container">
                            <div className="recipe-input-div">
                                <input
                                    className="recipe-text-input"
                                    style={{ width: "min(500px, 90vw)" }}
                                    onChange={handleChange}
                                    value={desiredUrl}
                                    placeholder="http://www.cookbook.com/carbonara"
                                />
                                <img className="input-img" src={hyperlink} />
                            </div>

                            <div className="new-recipe-disclaimer">
                                By default, all new recipes are public, meaning that all Kasserole
                                users can see them. If you want to make a recipe private, you can
                                do so after it&apos;s created by editing that recipe.
                            </div>

                            <div>
                                <div
                                    style={{
                                        borderTop: "1px solid #E1E7ED",
                                        width: "100%",
                                        marginBottom: "24px",
                                    }}
                                ></div>
                                <div
                                    className={`create-recipe-btn${desiredUrl === "" ? "-disabled" : ""}`}
                                    onClick={() => desiredUrl !== "" && submitUrl()}
                                >
                                    <img src={desiredUrl === "" ? wandGrey : wand}/>
                                    <div className="create-recipe-btn-text">Craft Recipe</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {acknowledgeInputModalIsActive && (
                    <ModalWithAck
                        valid={true}
                        title="I'm on it!"
                        message="I'll analyze that web page, looking for a recipe. Assuming I find one, I'll extract the recipe. In a couple of minutes, look for it in your cookbook."
                        onClick={() => {
                            dismissModals();
                            navigate("/browse");
                        }}
                    />
                )}

                {invalidInputModalIsActive && (
                    <ModalWithAck
                        valid={false}
                        title="Oops!"
                        message="That doesn't look like a valid URL."
                        onClick={dismissModals}
                    />
                )}
            </>
    );
}