import PropTypes from "prop-types";
import React from "react";
import {Checkbox} from "../base/Checkbox";
import {nfilters} from "../../settings";

export const NutritionalFilterCheckboxes = ({
                                                nutritionalFilterState,
                                                toggleFilter,
                                            }) => {

    return (
        <div className="nutritional-filter-container">
            {nfilters.map((filter) => (
                <div key={filter.name}>
                    <Checkbox
                        checked={nutritionalFilterState[filter.name]}
                        onChange={() => toggleFilter(filter.name)}
                        label={filter.label}
                    />
                </div>
            ))}
            <br/>
        </div>
    );
};

NutritionalFilterCheckboxes.propTypes = {
    nutritionalFilterState: PropTypes.object.isRequired,
    toggleFilter: PropTypes.func.isRequired,
};