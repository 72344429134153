import PropTypes from "prop-types";
import React from "react";
import { useState } from "react";

export const CustomButton = ({
  imgSrc,
  imgSrcHover,
  text,
  className,
  iconClassName,
  onClickAction,
}) => {
  if (imgSrcHover == undefined) {
    imgSrcHover = imgSrc;
  }

  const [hover, setHover] = useState(false);

  const handleMouseOver = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <button
      className={className}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={() => onClickAction()}
    >
      <img className={iconClassName} src={hover ? imgSrcHover : imgSrc} />
      {text}
    </button>
  );
};

CustomButton.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  imgSrcHover: PropTypes.string,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  iconClassName: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
};
