import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import * as Constants from "../../components/Constants";
import * as LocalCache from "../../components/LocalCache";
import axios from "axios";

export const useCreateFromImage = () => {
    const [newImage, setNewImage] = useState(null);
    const [imageFileToUpload, setImageFileToUpload] = useState(null);
    const [acknowledgeInputModalIsActive, setAcknowledgeInputModalIsActive] =
        useState(false);
    const navigate = useNavigate();
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    const submitImage = async () => {
        const encodedUser = Constants.encodeUser(user);
        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );
        const new_guid = Constants.generateGUID();
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_CREATE_RESOURCE,
            "mode=recipeFromImage" + "&user=" + encodedUser + "&guid=" + new_guid
        );
        setAcknowledgeInputModalIsActive(true);
        LocalCache.add(new_guid, LocalCache.NEW_STATUS);

        const reader = new FileReader();
        reader.onloadend = () => {
            const arr = new Uint8Array(reader.result).subarray(0, 4);
            let header = "";
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            const mimeType = Constants.getImageMimeTypeFromHeader(header);
            if (mimeType === "") {
                console.log("Unrecognized mime type");
            } else {
                // TODO: Move this to external-api.service.js
                axios.post(url, imageFileToUpload, {
                    headers: {
                        "Content-Type": mimeType,
                        Authorization: "Bearer " + accessToken,
                    },
                });
            }
        };
        reader.readAsArrayBuffer(imageFileToUpload);
    };

    const handleImageFileChange = (event) => {
        const x = event.target.files[0];
        setImageFileToUpload(x);
        if (x != null) {
            let reader = new FileReader();
            reader.onload = function (event) {
                setNewImage(event.target.result);
            };
            reader.readAsDataURL(x);
        }
    };

    function dismissModal() {
        setAcknowledgeInputModalIsActive(false);
    }

    return {
        user,
        imageFileToUpload,
        acknowledgeInputModalIsActive,
        dismissModal,
        handleImageFileChange,
        submitImage,
        navigate,
        newImage,
    }
}