import React from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import loading from "assets/icons/Kasserole_LoadingAnimation.json";

export default function LoadingSpinner() {
  return (
    <div className="loading-page">
      <div className="loading-overlay">
        <Player className="loading-spinner" src={loading} loop autoplay />
      </div>
    </div>
  );
}
