/*
VideoPlayer.js
Adam Berger
Aug 2023
*/

import React, { useEffect, useState } from "react";
import localforage from "localforage";
import * as Constants from "components/Constants";

const VideoPlayer = () => {
  const [videoUrl, setVideoUrl] = useState(null);
  const videoLibrary = Constants.HOMESCREEN_VIDEO_URLS;

  localforage.config({
    name: "kasserole",
    storeName: "rgvideos",
  });

  function select_video(num_videos) {
    // different video each day.
    const now = new Date(); // Get the current date
    // Get the current year (four digits, e.g., 2023)
    const currentYear = now.getFullYear();
    // Create a new Date object for January 1st of the current year
    const yearStart = new Date(currentYear, 0, 1);
    // Calculate the time difference in milliseconds between now and January 1st
    const timeDifference = now - yearStart;
    // Convert the time difference to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const daysElapsed = Math.floor(timeDifference / (24 * 60 * 60 * 1000));
    // what is today's video?
    const selected_video = daysElapsed % num_videos;
    return selected_video;
  }

  const index = select_video(videoLibrary.length);
  const remoteUrl = videoLibrary[index];

  const fetchAndStoreVideo = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    await localforage.setItem(url, blob);
    return blob;
  };

  useEffect(() => {
    const loadVideo = async () => {
      // Try to get the video from localForage
      let videoBlob = await localforage.getItem(remoteUrl);

      // If not found, fetch from the remote URL and store it in localForage
      if (!videoBlob) {
        videoBlob = await fetchAndStoreVideo(remoteUrl);
      }

      // Create an ObjectURL for the Blob and set it for video playback
      const objectUrl = URL.createObjectURL(videoBlob);
      setVideoUrl(objectUrl);
    };
    loadVideo();
  }, []);

  return (
    <div>
      {videoUrl ? (
        <div className="videocontainer">
          <video
            autoPlay="autoplay"
            loop="loop"
            muted
            className="video"
            src={videoUrl}
          >
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default VideoPlayer;
