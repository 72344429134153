import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import * as Constants from "../../components/Constants";
import * as LocalCache from "../../components/LocalCache";
import {callExternalApi} from "../../components/external-api.service";
import {MAX_CHARS_PER_INPUT_LINE} from "../../settings";

export const useCreateFromIngredients = () => {
    const [invalidInputModalIsActive, setInvalidInputModalIsActive] =
        useState(false);
    const [acknowledgeInputModalIsActive, setAcknowledgeInputModalIsActive] =
        useState(false);
    const [inputValue, setInputValue] = useState("");
    const navigate = useNavigate();
    const [inputFeedback, setInputFeedback] = useState("");
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    function dismissModals() {
        setInvalidInputModalIsActive(false);
        setAcknowledgeInputModalIsActive(false);
    }

    const handleChangeInInputIngredients = (event) => {
        // force the input to be 20 characters or less for each ingredient (i.e. each input line)
        let input = event.target.value;
        let lines = input.split("\n");
        let isTooLong = false;
        for (let i = 0; i < lines.length; i++) {
            if (lines[i].length > MAX_CHARS_PER_INPUT_LINE) {
                lines[i] = lines[i].substring(0, MAX_CHARS_PER_INPUT_LINE); // Truncate the line
                isTooLong = true;
            }
        }

        setInputValue(lines.join("\n"));

        // Set the feedback message
        if (isTooLong) {
            setInvalidInputModalIsActive(true);
            setInputFeedback(
                `Type just one ingredient per line. Be sure each ingredient is no more than ${MAX_CHARS_PER_INPUT_LINE} characters.`
            );
        } else {
            setInvalidInputModalIsActive(false);
            setInputFeedback("");
        }
    };

    const submitIngredients = async () => {
        const encodedUser = Constants.encodeUser(user);

        // check and canonicalize input
        let ri = inputValue.trim();
        if (!ri || Constants.countLinesInString(ri) < 2) {
            setInvalidInputModalIsActive(true);
            setInputFeedback(
                "Please provide at least two ingredients, each on their own line."
            );
            return;
        }
        ri = ri.split("\n").join(","); // replace newlines with commas
        const encodedIngredients = Constants.encodeUserInputForURI(ri);
        const new_guid = Constants.generateGUID();

        // get recipe from OpenAI
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_CREATE_RESOURCE,
            "mode=recipeFromIngredients" +
            "&guid=" +
            new_guid +
            "&ingredients=" +
            encodedIngredients +
            "&user=" +
            encodedUser
        );

        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );
        setAcknowledgeInputModalIsActive(true);
        LocalCache.add(new_guid, LocalCache.NEW_STATUS);
        callExternalApi(url, accessToken);
    };

    return {
        user,
        handleChangeInInputIngredients,
        inputValue,
        submitIngredients,
        acknowledgeInputModalIsActive,
        navigate,
        invalidInputModalIsActive,
        inputFeedback,
        setInvalidInputModalIsActive
    }
}