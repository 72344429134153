/*
Footer.js
Adam Berger
July 2023
*/

import React, { useState } from "react";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "components/Constants";
import ModalWithoutAck from "components/ModalWithoutAck";
import FeedbackModal from "components/FeedbackModal";

export default function Footer() {
  const [feedback, setFeedback] = useState(null);
  const [feedbackModalIsActive, setFeedbackModalIsActive] = useState(false);
  const [thankYouModalIsActive, setThankYouModalIsActive] = useState(false);
  const { user } = useAuth0();

  async function submitFeedback() {
    if (!feedback || !feedback.trim()) {
      return;
    }
    const encodedUser = Constants.encodeUser(user);
    const encodedFeedback = Constants.encodeUserInputForURI(feedback);

    const url = Constants.generateSecureApiUrl(
      Constants.APIG_FEEDBACK_RESOURCE,
      "user=" + encodedUser + "&feedback=" + encodedFeedback
    );

    try {
      await axios(url);
      resetFeedbackForm();
      setThankYouModalIsActive(true);
    } catch (error) {
      console.error("Error submitting feedback", error);
    }
    resetFeedbackForm();
    setThankYouModalIsActive(true);
  }

  function resetFeedbackForm() {
    setFeedbackModalIsActive(false);
    setFeedback("");
  }

  return (
    <>
      {feedbackModalIsActive && (
        <FeedbackModal
          feedback={feedback}
          onFeedbackChange={setFeedback}
          onSubmitFeedback={submitFeedback}
          onCancel={resetFeedbackForm}
        />
      )}

      {thankYouModalIsActive && (
        <ModalWithoutAck
          title="Thank you!"
          message="Thanks for your feedback!"
          delay={2500}
          onClose={() => {
            setThankYouModalIsActive(false);
          }}
        />
      )}
    </>
  );
}
