/*
Button.js
Adam Berger
July 2023
*/

import React from 'react';
import MKButton from 'components/MKButton';
import PropTypes from 'prop-types';  

const Button = (props) => {
  return <MKButton style={{padding: "4px", borderRadius: '0'}} {...props} />;
};


Button.propTypes = {
    children: PropTypes.node.isRequired,  
    onClick: PropTypes.func.isRequired, 
  };

export default Button;
