import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import logoutIcon from "assets/icons/logout.svg";

const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <div className="nav-btn-container">
      <div
        className="nav-btn logout-btn"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        <img className="btn-icon log-out-btn-icon" src={logoutIcon} />
        <div className="log-out-btn-text">Log out</div>
      </div>
    </div>
  );
};

export default LogoutButton;
