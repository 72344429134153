/*
WrappedImg.js
Adam Berger
catches a 404 for an img and renders placeholder image
July 2023
*/

import React, { useState } from "react";
import PropTypes from "prop-types";

const WrappedImg = ({ src, defaultSrc, alt, style, className, onError }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
    if (onError) {
      onError();
    }
  };

  const actualSrc = hasError || !src ? defaultSrc : src;

  return (
    <div className="wrapped-img-container">
      <img
        src={actualSrc}
        onError={handleError}
        alt={alt || "image"}
        style={style}
        className={className}
      />
    </div>
  );
};

WrappedImg.propTypes = {
  src: PropTypes.string,
  defaultSrc: PropTypes.string.isRequired,
  alt: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  onError: PropTypes.func,
};

export default React.memo(WrappedImg);
