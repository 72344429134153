/*
CreateFromEmbeddedLink.js
Adam Berger
Oct 2023
*/

// test with: https://kasserole.com/el/316336aa25a74444bb42dd9a2e7b6f2f

import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Navbar from "components/Navbar";
import * as Constants from "components/Constants";
import Footer from "components/Footer";
import ModalWithAck from "components/ModalWithAck";
import RecipePageBackground from "components/RecipePageBackground";
import bgImage from "assets/images/1.png";
import LoadingSpinner from "components/LoadingSpinner";
import {useCreateFromEmbeddedLink} from "./useCreateFromEmbeddedLink";

// TODO: HANDLE CASE WHERE USER NOT LOGGED IN. CAN WE PASS THE GUID ALONG AS STATE?

export const CreateFromEmbeddedLink = () => {
    const {
        user,
        viewState,
        navigate,
        errorMessage,
    } = useCreateFromEmbeddedLink()

    return ( user === null ? <div>Loading...</div> :
        <>
            <Navbar />
            <RecipePageBackground bgImage={bgImage}>
                {viewState === "loading" && (
                    <Modal isOpen={true} className="modal-dialog-centered">
                        <ModalHeader>Adding recipe...</ModalHeader>
                        <ModalBody>
                            <center>
                                <LoadingSpinner />
                            </center>
                        </ModalBody>
                    </Modal>
                )}

                {viewState === "loaded" && (
                    <ModalWithAck
                        title="Done"
                        message="I've added that recipe to your Kasserole cookbook."
                        onClick={() => {
                            navigate(Constants.APP_BROWSE);
                        }}
                    />
                )}
            </RecipePageBackground>
            <Footer />

            {viewState === "error" && (
                <ModalWithAck
                    title="Error"
                    message={errorMessage}
                    onClick={() => navigate(Constants.APP_BROWSE)}
                />
            )}
        </>
    );
}
