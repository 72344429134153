import {useNavigate, useParams} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import * as Constants from "../../components/Constants";
import {callExternalApi} from "../../components/external-api.service";

export const useCreateFromEmbeddedLink = () => {
    const { guid } = useParams();
    const navigate = useNavigate();
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [errorMessage, setErrorMessage] = useState("");
    const [viewState, setViewState] = useState("loading"); // loading, loaded. error

    // avoid calling addRecipe() 2x on component load.
    let alreadyCalledAddRecipe = false;

    const addRecipe = async () => {
        if (alreadyCalledAddRecipe) {
            return;
        }
        alreadyCalledAddRecipe = true;
        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );
        const encodedUser = Constants.encodeUser(user);
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_SHARE_RESOURCE,
            "mode=fromLink" + "&user=" + encodedUser + "&guid=" + guid
        );

        const { data } = await callExternalApi(url, accessToken);

        if (data === null) {
            setErrorMessage("Network error - cannot reach server. Try again later.");
            setViewState("error");
            return;
        }

        if (data.includes("invalid guid")) {
            setErrorMessage(
                "That recipe does not exist or has recently been deleted"
            );
            setViewState("error");
            return;
        }



        setViewState("loaded");
    };

    useEffect(() => {
        let isMounted = true;
        const loadDataWrapper = async () => {
            await addRecipe();
            if (!isMounted) {
                return;
            }
        };
        loadDataWrapper();
        return () => {
            isMounted = false;
        };
    }, [getAccessTokenSilently]);

    return {
        user,
        viewState,
        navigate,
        errorMessage,
    }
}