/*
RecipePageBackground.js
Adam Berger
July 2023
*/

import React from 'react';
import PropTypes from 'prop-types';


const RecipePageBackground = ({ children, bgImage, blockType, show }) => {

  if (!show) { 
    return (
      <div>
         {children} 
         </div>
    )
  }

  return (
    <div style={{
      display: blockType, 
      backgroundImage: `url(${bgImage})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      width: '100%',
      minHeight: '85vh', 
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'auto'
    }}>
      {children}
    </div>
  );
}

RecipePageBackground.propTypes = {
  children: PropTypes.node.isRequired,  
  bgImage: PropTypes.string.isRequired, 
  blockType: PropTypes.string,
  show: PropTypes.bool
};

RecipePageBackground.defaultProps = {
  blockType: "flex",
  show: true
};

export default RecipePageBackground

