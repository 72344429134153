import React, { useState, useEffect, useRef } from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";

//import carousel from ReactStrap
import Carousel from "react-bootstrap/Carousel";

//import icons
import x from "assets/icons/x.svg";
import arrowRight from "assets/icons/ArrowRight.svg";
import dot from "assets/icons/dot.svg";
import dotDark from "assets/icons/dotDark.svg";
import caretRightDark from "assets/icons/CaretRightDark.svg";
import caretLeftDark from "assets/icons/CaretLeftDark.svg";
import caretRight from "assets/icons/CaretRight.svg";
import caretLeft from "assets/icons/CaretLeft.svg";

//import images
import welcomeImg1 from "assets/images/WelcomeImage1.jpg";
import welcomeImg2 from "assets/images/WelcomeImage2.jpg";
import welcomeImg3 from "assets/images/WelcomeImage3.jpg";
import welcomeStep2 from "assets/images/WelcomeStep2.jpg";
import welcomeStep2Mobile from "assets/images/WelcomeStep2Mobile.jpg";

const WelcomeMessage = ({ backdrop, toggle }) => {
  const isOpen = useState(true);

  const [page, setPage] = useState(0);

  const [step, setStep] = useState(0);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handle update of window width. Determines whether we show the video or not.
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      // Cleanup: remove event listener when component unmounts
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const infoArray = [
    {
      text: "Take a picture of a recipe in your favorite cookbook",
      subText:
        "I'll scan the image, extract the recipe and add it to your online cookbook.",
      imgSrc: welcomeImg1,
    },
    {
      text: "Type in a URL of an online recipe",
      subText: "I'll fetch the recipe and add it to your online cookbook.",
      imgSrc: welcomeImg2,
    },
    {
      text: "Type in a set of ingredients",
      subText:
        " I'll come up with a recipe, which you can add to your online cookbook.",
      imgSrc: welcomeImg3,
    },
  ];

  const carouselRef = useRef(null);

  const handlePrev = () => {
    if (page > 0) {
      //carouselRef.current.prev();
      setPage(page - 1);
    }
  };

  const handleNext = () => {
    if (page < 2) {
      //carouselRef.current.next();
      setPage(page + 1);
    }
  };

  const nextStep = () => {
    if (step < 2) {
      setStep(step + 1);
    }
  };

  if (!isOpen) {
    return <> </>;
  } else
    return (
      <>
        <Modal
          backdrop={backdrop}
          toggle={toggle}
          isOpen={isOpen}
          className="modal-dialog-centered"
        >
          {step === 0 && (
            <div className="welcome-modal-container">
              <div className="welcome-modal">
                <h4 className="welcome-modal-title">
                  Welcome to Kasserole
                  <img onClick={toggle} src={x} />
                </h4>
                <h5 className="welcome-modal-subtitle">
                  Kasserole is an online cookbook where you can:
                </h5>
                <div className="welcome-modal-subtext-container">
                  <p className="welcome-modal-subtext-bold">
                    {infoArray[page].text}
                  </p>
                  <p className="welcome-modal-subtext">
                    {infoArray[page].subText}
                  </p>
                </div>
                <Carousel
                  className="carousel-container"
                  controls={false}
                  indicators={false}
                  interval={null}
                  ref={carouselRef}
                  activeIndex={page}
                >
                  <Carousel.Item>
                    <img
                      className="welcome-carousel-img"
                      src={infoArray[0].imgSrc}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="welcome-carousel-img"
                      src={infoArray[1].imgSrc}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="welcome-carousel-img"
                      src={infoArray[2].imgSrc}
                    />
                  </Carousel.Item>
                </Carousel>

                <div className="welcome-modal-navigate-container">
                  <img
                    className="welcome-modal-prev-icon"
                    onClick={handlePrev}
                    src={page === 0 ? caretLeft : caretLeftDark}
                  />
                  <div className="welcome-modal-dots-container">
                    <img src={page === 0 ? dotDark : dot} />
                    <img src={page === 1 ? dotDark : dot} />
                    <img src={page === 2 ? dotDark : dot} />
                  </div>
                  <img
                    className="welcome-modal-next-icon"
                    onClick={handleNext}
                    src={page === 2 ? caretRight : caretRightDark}
                  />
                </div>

                <button onClick={nextStep} className="welcome-modal-btn">
                  Continue
                  <img src={arrowRight} />
                </button>
              </div>
            </div>
          )}

          {step === 1 && (
            <div className="welcome-modal-container">
              <div className="welcome-modal">
                <h4 className="welcome-modal-title">
                  Welcome to Kasserole
                  <img onClick={toggle} src={x} />
                </h4>
                <div className="welcome-modal-list-container">
                  <p className="welcome-modal-subtext-bold">
                    To create a new recipe, follow these steps:
                  </p>
                  <ol className="welcome-modal-list">
                    <li className="welcome-modal-subtext">
                      Click “New Recipe” button.
                    </li>
                    <li className="welcome-modal-subtext">
                      Select an option from the dropdown list.
                    </li>
                  </ol>
                </div>
                <img
                  className="welcome-modal-img"
                  src={windowWidth > 600 ? welcomeStep2 : welcomeStep2Mobile}
                />

                <button onClick={nextStep} className="welcome-modal-btn">
                  Continue
                  <img src={arrowRight} />
                </button>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className="welcome-modal-container">
              <div className="welcome-modal">
                <h4 className="welcome-modal-title">
                  Welcome to Kasserole
                  <img onClick={toggle} src={x} />
                </h4>
                <div className="welcome-modal-subtext-container">
                  <p className="welcome-modal-subtext-alt">
                    Using the power of AI, we will:
                  </p>
                  <ul className="welcome-modal-list">
                    <li className="welcome-modal-subtext">
                      Generate nutritional information for recipes
                    </li>
                    <li className="welcome-modal-subtext">
                      Give you the ability to modify recipes based on common
                      diets / allergies
                    </li>
                    <li className="welcome-modal-subtext">
                      Generate images of recipes (you can also upload your own)
                    </li>
                  </ul>
                </div>

                <button onClick={toggle} className="welcome-modal-btn">
                  Explore Kasserole
                  <img src={arrowRight} />
                </button>
              </div>
            </div>
          )}
        </Modal>
      </>
    );
};

WelcomeMessage.propTypes = {
  onClick: PropTypes.func,
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};

export default WelcomeMessage;
