/*
VariantModal.js
Adam Berger
July 2023
*/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";

import * as Constants from "components/Constants";
import * as LocalCache from "components/LocalCache";
import { callExternalApi } from "components/external-api.service";
import ModalWithAck from "components/ModalWithAck";

//import assets
import modalX from "assets/icons/modal-x.svg";
import checkMarkWhite from "assets/icons/check-mark-white.svg";
import radioButton from "assets/icons/RadioButton.svg";
import radioButtonChecked from "assets/icons/RadioButtonChecked.svg";

export default function VariantModal({ backdrop, toggle, guid, onClick }) {
  const navigate = useNavigate();
  const [modalIsActive, setModalIsActive] = useState(false);
  const [variantType, setVariantType] = useState("");
  const [selectedVariant, setSelectedVariant] = useState("");

  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const doit = async (type) => {
    if (type === "") {
      return;
    }
    setModalIsActive(!modalIsActive);
    setVariantType(type);
    const encodedUser = Constants.encodeUser(user);
    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    const new_guid = Constants.generateGUID();
    LocalCache.add(new_guid, LocalCache.NEW_STATUS);
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_CREATE_RESOURCE,
      "mode=variantFromGuid" +
        "&guid=" +
        guid +
        "&new_guid=" +
        new_guid +
        "&user=" +
        encodedUser +
        "&type=" +
        type
    );
    callExternalApi(url, accessToken);
    setModalIsActive(!modalIsActive);
  };

  function dontdoit() {
    onClick(false);
  }

  function dismissModal() {
    setModalIsActive(false);
    navigate(-1);
  }

  function handleRadioChange(variant) {
    setSelectedVariant(variant);
  }

  const renderButton = (variant, index) => {
    {
      if (selectedVariant == variants[index]) {
        return (
          <div
            className="variant-modal-button-selected"
            onClick={() => handleRadioChange(variant)}
          >
            <img className="variant-radio" src={radioButtonChecked} />
            {variant[0].toUpperCase() + variant.slice(1)}
          </div>
        );
      } else {
        return (
          <div
            className="variant-modal-button"
            onClick={() => handleRadioChange(variant)}
          >
            <img className="variant-radio" src={radioButton} />
            {variant[0].toUpperCase() + variant.slice(1)}
          </div>
        );
      }
    }
  };

  const variants = [
    "vegan",
    "dairy-free",
    "low-fat",
    "low-carb",
    "gluten-free",
  ];

  return (
    <>
      {!modalIsActive && (
        <div>
          <Modal
            backdrop={backdrop}
            toggle={toggle}
            isOpen={true}
            className="modal-dialog"
          >
            <div className="modal-header">
              <div className="modal-header-flex">
                <h5 className="modal-header-text">Customize this recipe</h5>
                <img
                  className="modal-header-x"
                  src={modalX}
                  onClick={dontdoit}
                />
              </div>
            </div>
            <ModalBody>
              <div className="modal-body-text">
                Select how you want to vary this recipe, and I&apos;ll create a
                new version for you.
              </div>
              <div className="variant-modal-btn-container">
                {variants.map((variant, index) => (
                  <div key={variant}>{renderButton(variant, index)}</div>
                ))}
              </div>
            </ModalBody>
            <div className="variant-modal-footer">
              <button
                className="variant-modal-btn variant-modal-btn-dark"
                onClick={() => doit(selectedVariant)}
              >
                <img src={checkMarkWhite} />
                Submit
              </button>
              <button
                className="variant-modal-btn variant-modal-btn-light"
                onClick={() => dontdoit()}
              >
                <img src={modalX} />
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      )}

      {modalIsActive && (
        <ModalWithAck
          backdrop={backdrop}
          toggle={toggle}
          valid={true}
          title="I'm on it!"
          message={`Let me work on that. Check your online cookbook in a minute or so for a new ${variantType} version of this recipe.`}
          onClick={() => dismissModal()}
        />
      )}
    </>
  );
}

VariantModal.propTypes = {
  guid: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};
