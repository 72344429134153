/*
ModalWithAck
Adam Berger
July 2023
*/

import React from "react";
import { Modal, ModalBody } from "reactstrap";
import PropTypes from "prop-types";

//import assets
import modalX from "assets/icons/modal-x.svg";
import xcircle from "assets/icons/XCircle.svg";
import arrows from "assets/icons/ArrowsCounterClockwise.svg";
import chefIcon from "assets/icons/chef-icon.png";
import check from "assets/icons/check-mark-white.svg";

/* Modal window that includes an "Ok" button for user to explicitly dismiss it. */
const ModalWithAck = ({
  toggle,
  backdrop,
  copy,
  error,
  title,
  message,
  valid,
  onClick,
  onTryAgain,
}) => {
  return (
    <>
      <div style={{ margin: 10 }}>
        <Modal
          backdrop={backdrop}
          toggle={toggle}
          isOpen={true}
          className="modal-dialog-centered"
        >
          <div className="modal-header">
            <div className="modal-header-flex">
              <h5 className="modal-header-text">{title}</h5>
              {!copy && (
                <img
                  className="modal-header-x"
                  src={modalX}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick();
                  }}
                />
              )}
            </div>
          </div>
          <ModalBody>
            <div className="modal-message-container">
              {error && <img className="modal-body-img-error" src={xcircle} />}
              <div
                style={valid ? { textAlign: "center" } : { textAlign: "left" }}
                className="modal-body-text"
              >
                {message}
              </div>
            </div>
            {valid && <img className="modal-body-img" src={chefIcon} />}
          </ModalBody>
          {copy && (
            <div className="variant-modal-footer">
              <button
                className="variant-modal-btn variant-modal-btn-dark"
                onClick={onClick}
              >
                <img src={check} />
                Continue
              </button>
            </div>
          )}
          {error && (
            <div className="variant-modal-footer">
              <button
                className="variant-modal-btn variant-modal-btn-dark"
                onClick={(e) => {
                  e.stopPropagation();
                  onClick();
                }}
              >
                <img src={arrows} />
                Try Again
              </button>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

ModalWithAck.propTypes = {
  error: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  onTryAgain: PropTypes.func,
  toggle: PropTypes.func,
  backdrop: PropTypes.bool,
  copy: PropTypes.bool,
};

export default ModalWithAck;
