import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import * as Constants from "../../components/Constants";
import * as LocalCache from "../../components/LocalCache";
import {callExternalApi} from "../../components/external-api.service";

export const useCreateFromUrl = () => {
    const [desiredUrl, setDesiredUrl] = useState("");
    const [invalidInputModalIsActive, setInvalidInputModalIsActive] =
        useState(false);
    const [acknowledgeInputModalIsActive, setAcknowledgeInputModalIsActive] =
        useState(false);
    const navigate = useNavigate();
    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

    const handleChange = (event) => {
        const desiredUrl = event.target.value;
        setDesiredUrl(desiredUrl);
    };

    function dismissModals() {
        setInvalidInputModalIsActive(false);
        setAcknowledgeInputModalIsActive(false);
    }

    const submitUrl = async () => {
        const encodedUser = Constants.encodeUser(user);
        // check and canonicalize the URL the user entered
        if (!desiredUrl || !desiredUrl.trim()) {
            return; // if url is blank, just fail silently (for now)
        }
        var ri = desiredUrl.trim();
        if (!Constants.isValidUrl(ri)) {
            console.log("invalid url: " + ri); // debug
            setInvalidInputModalIsActive(true);
            return; // if url is invalid, just fail silently (for now)
        }
        if (!ri.startsWith("http") && !ri.startsWith("https")) {
            ri = "https://" + ri;
        }
        const encodedDesiredUrl = Constants.encodeUserInputForURI(ri);
        const new_guid = Constants.generateGUID();
        const url = Constants.generateSecureApiUrl(
            Constants.APIG_CREATE_RESOURCE,
            "&mode=recipeFromUrl" +
            "&url=" +
            encodedDesiredUrl +
            "&guid=" +
            new_guid +
            "&user=" +
            encodedUser
        );
        const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
            getAccessTokenSilently,
            loginWithRedirect,
            navigate
        );
        setAcknowledgeInputModalIsActive(true);
        LocalCache.add(new_guid, LocalCache.NEW_STATUS);
        callExternalApi(url, accessToken);
    };

    return {
        user,
        handleChange,
        desiredUrl,
        submitUrl,
        acknowledgeInputModalIsActive,
        dismissModals,
        navigate,
        invalidInputModalIsActive
    }
}