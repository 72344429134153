/*
external-api-service.js
Adam Berger
July 2023
*/

import axios from "axios";

const MAX_RETRIES = 3
const WAIT_BETWEEN_RETRIES = 3000 // ms

export const callExternalApi = async (url, accessToken) => {
  const config = {
    method: 'GET',
    url: url,
    headers: { 
      'Authorization': `Bearer ${accessToken}`
    }
  };

  return _callExternalApiWithRetries(config, MAX_RETRIES)
}

// ====== internal functions ======

function _delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const _callExternalApiWithRetries = async (config, numRetriesRemaining) => {
  try {
    const response = await axios(config);
    const { data } = response;
    return {
      data,
      error: null,
    };
  } catch (error) {
    if (numRetriesRemaining > 0) {
      console.log(`Network call failed. Retry attempt remaining: ${numRetriesRemaining}`);
      console.log("URL: " + config.url)
      console.log("Error: " + error)
      await _delay(WAIT_BETWEEN_RETRIES)
      return _callExternalApiWithRetries(config, numRetriesRemaining - 1);
    }
    else {
      console.log(`Network call failed after ${MAX_RETRIES} attempts.`)
      if (axios.isAxiosError(error)) {
        const axiosError = error;
        const { response } = axiosError;
        let message = "http request failed";
        if (response && response.statusText) {
          message = response.statusText;
        }
        if (axiosError.message) {
          message = axiosError.message;
        }
        if (response && response.data && response.data.message) {
          message = response.data.message;
        }
        return {
          data: null,
          error: { message, },
        };
      }

      return {
        data: null,
        error: { message: error.message, },
      };
    }
  }
}

