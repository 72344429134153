/*
Home.js
(testbranch 2)
Adam Berger
July 2023
*/

import React from "react";
import homeImg from "assets/images/home_img.jpg";
import wand from "assets/icons/wand.svg";
import logoWhite from "assets/logo-white.svg";
import "../../home.css";
import { useHome } from "./useHome";
import VideoPlayer from "components/VideoPlayer";

export const Home = () => {
  const {
    showOneTimeWelcomeMessage,
    emailIsVerified,
    isAuthenticated,
    windowWidth,
    loginWithRedirect,
    handleReload,
  } = useHome();

  return (
    <>
      {!isAuthenticated && (
        <>
          <div style={{ position: "relative" }}>
            {/* Container for video and text overlay */}

            <div className="background">
              {windowWidth >= 768 && <VideoPlayer />}

              <div className="home-img-container">
                <div className="email-verification-logo-container">
                  <img src={logoWhite} />
                </div>
                {windowWidth < 768 && (
                  <img className="home-img" src={homeImg} />
                )}
                <div className="home-img-overlay"></div>

                <div className="home-content">
                  <h1 className="home-main-text">
                    Welcome to Kasserole! <br />
                    It&apos;s AI you can eat.
                  </h1>
                  <div className="home-subtext">
                    Explore diverse, mouthwatering recipes crafted by experts
                    and AI.
                    <br />
                    From quick meals to gourmet delights, embark on your
                    culinary adventure here!
                  </div>
                  <div className="home-btn">
                    <img className="home-btn-icon" src={wand} />
                    <div
                      onClick={() =>
                        loginWithRedirect({
                          appState: {
                            returnTo: "/",
                          },
                        })
                      }
                    >
                      Get Started
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isAuthenticated && emailIsVerified === false && (
        <>
          <div style={{ position: "relative" }}>
            {/* Container for video and text overlay */}

            <div className="background">
              {windowWidth >= 768 && <VideoPlayer />}

              <div className="home-img-container">
                <div className="home-img-overlay"></div>

                <div className="email-verification-logo-container">
                  <img src={logoWhite} />
                </div>
                {windowWidth < 768 && (
                  <img className="home-img" src={homeImg} />
                )}
                <div className="home-img-overlay"></div>
                <div className="email-verification-modal-container">
                  <div className="email-verification-modal">
                    <div className="email-verification-title">
                      Welcome to Kasserole
                    </div>
                    <div className="email-verification-text">
                      Thanks for registering. Just one more quick step.
                    </div>
                    <div className="email-verification-subtext">
                      Look for an email in your inbox. Once you click on the
                      link in that email, you&apos;ll be in!
                    </div>
                    <button
                      className="email-verification-btn"
                      onClick={handleReload}
                    >
                      I&apos;ve verified my email
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
