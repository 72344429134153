/*
DeleteModal.js
Adam Berger
July 2023
*/

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Modal, ModalBody } from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import ModalWithoutAck from "components/ModalWithoutAck";
import { callExternalApi } from "components/external-api.service";
import * as Constants from "components/Constants";
import * as LocalCache from "components/LocalCache";

//import assets
import modalX from "assets/icons/modal-x.svg";
import checkMarkWhite from "assets/icons/check-mark-white.svg";

export default function DeleteModal({ backdrop, toggle, guid, onClick }) {
  const navigate = useNavigate();
  const [ackModalIsActive, setAckModalIsActive] = useState(false);
  const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const doit = async () => {
    setAckModalIsActive(true);
    const encodedUser = Constants.encodeUser(user);
    LocalCache.add(guid, LocalCache.DELETE_STATUS);
    const url = Constants.generateSecureApiUrl(
      Constants.APIG_DELETE_RESOURCE,
      "guid=" + guid + "&user=" + encodedUser
    );
    const accessToken = await Constants.getAccessTokenSilentlyWithTimeout(
      getAccessTokenSilently,
      loginWithRedirect,
      navigate
    );
    callExternalApi(url, accessToken);

    // Nudge Algolia to update its index, so we avoid seeing the deleted recipe. This is not foolproof.
    Constants.refreshIndex();
  };

  function dontdoit() {
    onClick();
  }

  function dismissAckModal() {
    setAckModalIsActive(false);
    navigate(-1);
  }

  return (
    <>
      {!ackModalIsActive && (
        <div>
          <Modal
            backdrop={backdrop}
            toggle={toggle}
            isOpen={true}
            className="modal-dialog-centered"
          >
            <div className="modal-header">
              <div className="modal-header-flex">
                <h5 className="modal-header-text">Are you sure?</h5>
                <img
                  className="modal-header-x"
                  src={modalX}
                  onClick={dontdoit}
                />
              </div>
            </div>
            <ModalBody>
              <div className="modal-body-text">
                Are you sure you want to delete this recipe?
              </div>
            </ModalBody>
            <div className="variant-modal-footer">
              <button
                className="variant-modal-btn variant-modal-btn-dark"
                onClick={() => doit()}
              >
                <img src={checkMarkWhite} />
                Delete
              </button>
              <button
                className="variant-modal-btn variant-modal-btn-light"
                onClick={() => dontdoit()}
              >
                <img src={modalX} />
                Cancel
              </button>
            </div>
          </Modal>
        </div>
      )}

      {ackModalIsActive && (
        <ModalWithoutAck
          backdrop={backdrop}
          toggle={toggle}
          title="Ok"
          message="Your recipe has been deleted."
          delay={5000}
          onClose={dismissAckModal}
        />
      )}
    </>
  );
}

DeleteModal.propTypes = {
  guid: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  backdrop: PropTypes.bool,
  toggle: PropTypes.func,
};
