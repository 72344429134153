/*
Info.js
Adam Berger
July 2023
*/

import React from 'react';
import MKTypography from 'components/MKTypography';
import RecipePageBackground from 'components/RecipePageBackground';
import Footer from 'components/Footer';
import bgImage from 'assets/images/8.png';
import * as Constants from "components/Constants";
import Navbar from 'components/Navbar';

export const Info = () => {
    return (
        <>
            <Navbar/>
            <RecipePageBackground bgImage={bgImage} >
                <div className="page_container">
                    <MKTypography variant="body3">
                        <img src={Constants.SMALL_CHEF_IMAGE} alt="" style={{ width: 50 }} /> &nbsp;
                        Here are some things you can do with Kasserole, your online cookbook: <br /><br />
                        <ul>
                            <li> Search, edit, and share recipes</li>
                            <li>Take a picture of a recipe in your favorite cookbook, and upload the picture. I&apos;ll scan the image, extract the recipe, and add it to your online cookbook.</li>
                            <li>Type in a URL of an online recipe. I&apos;ll fetch the recipe and add it to your online cookbook.</li>
                            <li>Type in a set of ingredients and I&apos;ll come up with a recipe, which you can add to your online cookbook.</li>
                        </ul>
                        Feedback? Click on the link at the bottom of any screen.
                    </MKTypography>
                </div>
            </RecipePageBackground>
            <Footer />
        </>
    );
}
