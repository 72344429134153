/*
LocalCache.js

Oct 2023
Adam Berger

Sits in front of algolia, which can take ~5s to update its index.
Uses IndexedDB as backing store    
*/


export const NEW_STATUS = "new"
export const DELETE_STATUS = "delete"
export const EDIT_STATUS = "edit"

const CACHE_TTL = 600000 // 10 minutes in ms 
const DB_NAME = 'KasseroleDB';
const DB_VERSION = 1;
const RECIPE_STORE = 'recipes';

// Module-level variable to store the database connection
let dbConnection = null;

// keep track of in-progress adds, so that a subsequent 'get' or 'add' for the same guid blocks on it.  
const ongoingAdds = new Map();

export async function add(guid, status, name = "", full_image = null) {

  const now = new Date()
  const expiry = new Date(now.getTime() + CACHE_TTL)

  const item = {
    guid,
    status,  // one of the *ACTIONS constants above
    name,    // (new) name of the recipe
    full_image,  // binary
    expiration: expiry
  };

  // Function to perform the actual add operation
  const addOperation = async () => {
    return _open().then(db => {
      const transaction = db.transaction(RECIPE_STORE, 'readwrite');
      const store = transaction.objectStore(RECIPE_STORE);
      const request = store.put(item);
      return new Promise((resolve, reject) => {
        request.onsuccess = () => resolve();
        request.onerror = event => reject(event.target.error);
      });
    }).catch(error => {
      console.error('Error adding/updating item in IndexedDB:', error);
      throw error; // Re-throw the error for proper handling
    });
  };

  // Chain this add operation to any ongoing one for the same guid
  const previousAdd = ongoingAdds.get(guid) || Promise.resolve();
  const addPromise = previousAdd.then(addOperation);

  // Track the ongoing add operation
  ongoingAdds.set(guid, addPromise);

  // Wait for the add operation to complete before resolving
  await addPromise;

  // Clean up after the operation is complete
  ongoingAdds.delete(guid);
}


/* delete given recipe from cache */
export async function deleteByGuid(guid) {
  // wait until any ongoing adds for this guid are done
  if (ongoingAdds.has(guid)) {
    await ongoingAdds.get(guid);
  }

  try {
    const db = await _open();
    const transaction = db.transaction(RECIPE_STORE, 'readwrite');
    const store = transaction.objectStore(RECIPE_STORE);
    return new Promise((resolve, reject) => {
      const request = store.delete(guid);
      request.onsuccess = () => resolve();
      request.onerror = event => reject(event.target.error);
    });
  } catch (error) {
    console.error('Error removing recipe from IndexedDB:', error);
  }
}

/* delete all recipes with given status */
export async function deleteByStatus(status) {
  let numDeleted = 0;
  try {
    const db = await _open();
    const transaction = db.transaction(RECIPE_STORE, 'readwrite');
    const store = transaction.objectStore(RECIPE_STORE);
    return new Promise((resolve, reject) => {
      const request = store.openCursor();
      request.onsuccess = event => {
        const cursor = event.target.result;
        if (cursor) {
          if (cursor.value.status === status) {
            store.delete(cursor.primaryKey);
            numDeleted++;
          }
          cursor.continue();
        } else {
          console.log("Deleted " + numDeleted + " recipes with status " + status)
          resolve(); // All entries checked
        }
      };
      request.onerror = event => reject(event.target.error);
    });
  } catch (error) {
    console.error('Error resetting recipes by status in IndexedDB:', error);
  }
}

/* get all recently-changed recipes */
export async function get() {
  const new_recipes = new Set(), deleted_recipes = new Set(), edited_recipes = new Set();
  const names_of_recipes = {}, images_of_recipes = {};  // dictionaries 
  try {
    const recipes = await _getAllRecipes();
    const dateNow = new Date().getTime();

    for (const recipe of recipes) {

      // wait until any ongoing adds for this guid are done
      if (ongoingAdds.has(recipe.guid)) {
         await ongoingAdds.get(recipe.guid);
      }
      const expiry = new Date(recipe.expiration);

      if (dateNow > expiry) {
        await deleteByGuid(recipe.guid);
        continue;
      }

      switch (recipe.status) {
        case NEW_STATUS:
          new_recipes.add(recipe.guid);
          break;
        case DELETE_STATUS:
          deleted_recipes.add(recipe.guid);
          break;
        case EDIT_STATUS:
          edited_recipes.add(recipe.guid);
          break;
        default:
          console.log("Unknown status: " + recipe.status);
      }

      names_of_recipes[recipe.guid] = recipe.name;
      images_of_recipes[recipe.guid] = recipe.full_image;
    }
  } catch (error) {
    console.error('Error getting recipes from IndexedDB:', error);
  }
  return [new_recipes, deleted_recipes, edited_recipes, names_of_recipes, images_of_recipes];
}


/* private method:  Open or create database and object store */
function _open() {
  return new Promise((resolve, reject) => {
    if (dbConnection) {
      resolve(dbConnection);
      return;
    }
    const request = indexedDB.open(DB_NAME, DB_VERSION);
    request.onupgradeneeded = event => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains(RECIPE_STORE)) {
        db.createObjectStore(RECIPE_STORE, { keyPath: 'guid' });
      }
    };
    request.onsuccess = event => {
      dbConnection = event.target.result;
      resolve(dbConnection);
    };

    request.onerror = event => {
      // Access the error property only within this handler
      reject(event.target.error);
    };
  });
}


async function _getAllRecipes() {
  const db = await _open();
  const transaction = db.transaction(RECIPE_STORE, 'readonly');
  const store = transaction.objectStore(RECIPE_STORE);

  return new Promise((resolve, reject) => {
    const request = store.getAll();
    request.onsuccess = () => {
      resolve(request.result);
    };
    request.onerror = () => {
      reject(request.error);
    };
  });
}
